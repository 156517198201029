import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import Sidebar from './SideBar';
import './ProjetoListForm.css'; // Importa o CSS específico para ProjectsList
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para redirecionamento
import { GrEdit } from "react-icons/gr";

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]); // Estado para armazenar projetos selecionados
  const navigate = useNavigate(); // Hook para redirecionamento

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token de acesso não encontrado.');
        }

        const response = await axios.get('https://financeiro.multipla.tec.br/api/projeto-list/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('Response:', response.data); // Verifica a resposta

        // Verifica se a resposta contém projetos
        if (Array.isArray(response.data)) {
          setProjects(response.data);
        } else {
          throw new Error('Dados recebidos não estão no formato esperado.');
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleButtonClick = () => {
    navigate('/cadastro-projeto'); // Redireciona para a nova página
  };

  // Função para lidar com a seleção de projetos
  const handleCheckboxChange = (id) => {
    setSelectedProjects((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  // Função para excluir (soft-delete) os projetos selecionados
  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token de acesso não encontrado.');
      }

      const response = await axios.post(
        'https://financeiro.multipla.tec.br/api/projetos/soft-delete/',
        { ids: selectedProjects },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Delete response:', response.data);

      // Filtra os projetos excluídos do estado
      setProjects((prevProjects) =>
        prevProjects.filter((project) => !selectedProjects.includes(project.id))
      );

      // Limpa a seleção após a exclusão
      setSelectedProjects([]);
    } catch (error) {
      console.error('Erro ao excluir projetos:', error);
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <BounceLoader color="#009C95" size={50} />
      </div>
    );
  }

  if (error) {
    return <div className="error">Erro: {error}</div>;
  }

  return (
    <div className="projects-list-container"> {/* Novo contêiner flex */}
      <Sidebar style={{ width: '550px', backgroundColor: '#343a40' }} />
      <div className="projects-list">
        <div className="actions">
          <input
            type="text"
            placeholder="Pesquisar Projetos ..."
            value={""}
            onChange={""}
          />
          <button className="add-button" onClick={handleButtonClick}>
            Incluir Projeto
          </button>
          {selectedProjects.length > 0 && (
            <button className="delete-button" onClick={handleDelete}>
              Excluir Selecionados
            </button>
          )}
        </div>
        <h1>Projetos Associados</h1>
        {projects.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Selecionar</th>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Data de Início</th>
                <th>Data de Fim</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedProjects.includes(project.id)}
                      onChange={() => handleCheckboxChange(project.id)}
                    />
                  </td>
                  <td>{project.nome}</td>
                  <td>{project.descricao}</td>
                  <td>{project.data_inicio}</td>
                  <td>{project.data_termino}</td>
                  <td>{project.status}</td>
                  <td>
        <button className="button-custom-edit" onClick={() => navigate(`/editar-projeto/${project.id}`)}>
          <GrEdit /> Editar
        </button>
      </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Nenhum projeto encontrado.</p>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
